<template>
    <h1>404</h1>
</template>

<script>
export default {
    name: "NotFoundPage"
}
</script>

<style>

</style>